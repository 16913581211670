import {DOCUMENT} from '@angular/common';
import {inject, InjectionToken} from '@angular/core';

export interface ExtendedWindow extends Window {
  fbq: (...args: any[]) => void;
  _fbq: (...args: any[]) => void;
  dataLayer: {
    [key: string]: unknown;
    push(payload: unknown): void;
  };
}

export const WINDOW = new InjectionToken<ExtendedWindow>(
  'An abstraction over global window object',
  {
    factory: () => {
      const {defaultView} = inject(DOCUMENT);

      if (!defaultView) {
        throw new Error('Window is not available');
      }

      return (defaultView as any);
    },
  },
);
